import {NavLink} from "react-router-dom";
import {navList} from "../../components/Header";

import "./page404.scss"

const Page404 = () => {

  return (
    <div className="page404">
      <div className="page404-content">
        <div className={`page404-title-overlay`}>
          <h2 className={'page404-title'}>404</h2>
        </div>
        <p className="page404-message">
          This link is broken. We’re so sorry about that! Just try pages belows instead.
        </p>
        <ul className='page404-menu'>
          {navList.map(({id, title, link}) => (
            <li className='page404-menu-item' key={id}>
              <NavLink to={link} style={({isActive}) => ({color: "#C5C8D3"})} >
                {title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Page404