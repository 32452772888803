import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { hydrate, render } from "react-dom";

import './index.scss';
import FontFaceObserver from 'fontfaceobserver';


//const root = ReactDOM.createRoot(document.getElementById('root'));

//for preloading fonts before page is loaded
new FontFaceObserver('Metropolis').load().then(() => {
  const rootElement = document.getElementById("root");
  if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
  } else {
    render(<App />, rootElement);
  }

  // root.render(
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // );
});

