import {FooterItem} from '../FooterItem';
import { useLocation } from 'react-router-dom'

import './Footer.scss'

const footerLinks = [
  {
    id: '1',
    title: 'linkedin',
    link: 'https://www.linkedin.com/company/e-tring',
    isDisabled: false
   }
  // {
  //   id: '5',
  //   title: 'behance',
  //   link: '#',
  //   isDisabled: true
  // }
]

export const Footer = () => {
  const location = useLocation();
  const pathes = ['genesys', 'services'];

  const addStopPoint = ()=>{
    const path = location.pathname.replace(/\//gi,'');
    if (!path || pathes.indexOf(path)>-1) return true;
    return false;
  }

  return (
    <footer className={`footer ${addStopPoint()? 'stop-point':''}`}>
      <div className='footer-inner'>
        <ul className='footer-list'>
          {footerLinks.map(link => <FooterItem key={link.id} {...link} />)}
        </ul>

        <p className='copyrigth'>
          2022 © tring.com
        </p>
      </div>
    </footer>
  )
}
