import React from "react";

import SupportSection from "./subcomponents/SupportSection";
import SolutionSection from "./subcomponents/SolutionSection";
import ServicesSection from "./subcomponents/ServicesSection";
import ResultsSection from "./subcomponents/ResultSection";
import FormSectionGenesys from "./subcomponents/FormSection";

import "./genesys.scss"
import {Helmet} from "react-helmet";


const Genesys = () => {
  return (
    <div className="genesys page-wrapper">
      <Helmet
        title="Tring | Genesys"
        meta={[
          {"name": "description", "content": "Tring supports and customize Genesys services"}
        ]}
      >
        <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Genesys",
                "item": "https://etring.com/genesys/"
              }]
            }`}
        </script>
      </Helmet>
      <SupportSection />
      <SolutionSection />
      <ServicesSection />
      <ResultsSection />
      <FormSectionGenesys />
    </div>
  )
}

export default Genesys
