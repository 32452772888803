import React from "react";
import {TitleWithPartlyOverlay} from "../../components/TitleWithOverlay";
import Form from "../../components/Form";
import {locationsList} from "../contacts";
import {useIsInViewport} from "../../heplers";

import "./hireUs.scss"

const Index = () => {
  const [formRef, isFormInView] = useIsInViewport()
  const [locationsRef, isLocationsInView] = useIsInViewport()

  return (
    <div className="hire page-flex-wrapper">
      <div className="hire-inner">
        <TitleWithPartlyOverlay
          title={{beforeAnimation: "Every", animated: "idea", afterAnimation: "matters"}}
          className={"hire-title"}
        />

        <div className="hire-content">
          <div className={`hire-form appear ${isFormInView ? "appear-in" : "appear-out"}`} ref={formRef}>
            <Form requiredAdditionalField={"idea"}/>
          </div>
          <div className={`hire-locations appear ${isLocationsInView ? "appear-in" : "appear-out"}`} ref={locationsRef}>
            <h4 className="hire-locations-title">our location</h4>

            <div className='hire-contacts' >
              {locationsList.map(({address, flag, tel, email}, order) => (
                <div key={order} className="hire-contact">
                  <div className='contacts-address' >
                    <p>{address}</p>
                    <img src={flag.srcImg} alt={flag.alt}/>
                  </div>
                  <p>{tel}</p>
                  <a href={`mailto:${email}`} className='contacts-email'>{email}</a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
