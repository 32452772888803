import React, {useState, useEffect} from "react";
import {TitleWithOverlay} from "../../components/TitleWithOverlay";
import {useIsInViewport} from "../../heplers";
import {servicesList} from "./data";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";

import './Services.scss'


const ServiceItem = ({title, titleBackground,content, list, orderNum}) => {
  //for forward and back, but without second item
  const observerOptions = orderNum === servicesList.length - 1 ? {rootMargin: '0% 0% 0% 0%'} : { rootMargin: '0% 0% -40% 0%' }

  const [isAnimateIn, setIsAnimateIn] = useState(false)
  const [contentRef, isContentInView] = useIsInViewport({rootMargin: '0% 0% 0% 0%'}) //will be just forward without back animation

  useEffect(() => {
    setIsAnimateIn(true)
  }, [])

  useEffect(() => {
    setIsAnimateIn(isContentInView)
  }, [isContentInView])

  return (
    <div className={'services-subwrapper stop-point'}>
      <div className={`services-item appear ${isAnimateIn ? "appear-in" : "appear-out"}`} ref={contentRef} >
        <div className='services-content'>
          <TitleWithOverlay title={title} className={`services-title is-blue`} bgColor={titleBackground}/>
          <p className="services-text">{content}</p>
        </div>

        <ul className='services-list'>
          {list.map(item => (
            <li key={item.id} className="services-list-item ">
              <div className={`services-list-item-img ${item.imgClass} svg`} />
              <div className="services-list-item-title">{item.item}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


const Services = () => {
  return (
    <>
      <Helmet
        title="Tring | Services"
        meta={[
          {"name": "description", "content": "We offer a wide range of software development services, including assisting our clients in maintaining existing IT systems and applications, or building new applications"}
        ]}
      >
        <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Services",
                "item": "https://etring.com/services/"
              }]
            }`}
        </script>
      </Helmet>
      <div className="services page-wrapper">
        {servicesList.map((item, order) => (<ServiceItem {...item} orderNum={order} key={item.id}/>))}
      </div>
    </>
  )

}

export default Services


ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  titleBackground: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    imgClass: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  })),
  orderNum: PropTypes.number
}
