import {useState} from 'react';
import {NavItem} from '../NavItem';
import {NavLink} from "react-router-dom";

import LogoHexs from '../../assets/logo-hexs.svg'
import LogoText from '../../assets/logo-tring.svg'

import './Header.scss'

export const navList = [
  {
    id: '1',
    title: 'Services',
    link: '/services/'
  },
  {
    id: '2',
    title: 'genesys',
    link: '/genesys/'
  },
  {
    id: '3',
    title: 'career',
    link: '/career/'
  },
  {
    id: '4',
    title: 'contacts',
    link: '/contacts/'
  }
]

export const Header = () => {
  const [isToggled, setToggled] = useState(false)

  const handleToggle = () => {
    setToggled(prev => !prev)
  }

  const closeMenu = () => {
    setToggled(false)
  }

  return (
    <header className='header'>
      <div className='header-inner'>
        <div className='logo'>
          <NavLink to={"/"} className='logo-link' onClick={closeMenu}>
            <img src={LogoHexs} alt='logo' />
            <img src={LogoText} alt='logo'/>
          </NavLink>
        </div>

        <nav className={isToggled ? 'nav toggled' : 'nav toggled-off'}>
          <div className='toggle-btn' onClick={handleToggle}>
            <div className='toggle-bar'></div>
            <div className='toggle-bar'></div>
            <div className='toggle-bar'></div>
          </div>

          <ul className='menu'>
            {navList.map(item => <NavItem
              key={item.id}
              {...item}
              closeMenu={closeMenu}
            />)}
          </ul>
        </nav>
      </div>
    </header>
  )
}
