import React from "react";
import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import Slider from "react-slick";
import CustomerGenesys from "../../../assets/homePage/customer-genesys.jpg"
import CustomerBetacom from "../../../assets/homePage/customer-betacom.jpg"
import Customer_1 from "../../../assets/homePage/customer _1.jpg";
import Customer_2 from "../../../assets/homePage/customer_2.jpg";
import AnimatedWrapper from "../../../components/animations/AnimatedWrapper";
import {useIsInViewport} from "../../../heplers";


const customersList = [
  {
    id: "1",
    img: CustomerGenesys,
    name: "Slava Zhakov",
    position: "Genesys, Chief technical officer",
    feedback: `During our co-operation, Tring, Inc. has proven to be a responsible partner providing services of high 
      quality given time frames. We want to thank Mr. Arkady Erlikhman, CEO and owner of Tring, Inc., for the services provided. 
      We are fully satisfied with the professionalism of Tring, Inc. team, the level of knowledge, the innovative tools and methodologies used. 
      We would like to continue a long-term engagement with Tring, Inc.`
  },
  {
    id: "2",
    img: CustomerBetacom,
    name: "Gaston Ezequiel Di Filippo",
    position: "betacom, Chief technical officer",
    feedback: `Our feedback is absolutely positive, we are always pleased to work with Tring. 
      Technicians of your company are very skilled for the topics asked and the communication in English is excellent. 
      Also, the pricing and timing are quite reasonable (could be better sometimes, but working in the same area I understand that 
      it's difficult and not always possible to improve these two points).`
  },
  {
    id: "3",
    img: Customer_1,
    name: "Igor Neyman",
    position: "SteppeChange, President & CEO",
    feedback: `We attracted Tring for implementation of the Genesys solution in our company. 
      The team also helped us to integrate the products with another software. The project was successful and we continue to work with 
      Tring as they help us to implement changes and provide support.`
  },
  {
    id: "4",
    img: Customer_2,
    name: "Dan Kikinis",
    position: "otopy, President & CEO",
    feedback: `We are grateful to the Tring team for their input and contribution. We involved the company for a specific 
      project with tough deadlines so the team worked under severe time pressure. During the project the team was properly organized and managed. 
      They demonstrated outstanding performance and could effectively work with our team and other vendors. 
      All deadlines were met with great quality.`
  }
]

const customersSliderDots = [
  {
    id: "1",
    title: "genesys",
  },
  {
    id: "2",
    title: "betacom",
  },
  {
    id: "3",
    title: "SteppeChange",
  },
  {
    id: "4",
    title: "Otopy",
  }
]

const CustomersSection = () => {
  const [titleRef, isTitleInView] = useIsInViewport()
  const [sliderRef, isSliderInView] = useIsInViewport()

  const renderSlide = ({id, img, name, position, feedback}) => (
    <div key={id}>
      <div className="customer-slide" >
        <img src={img} alt="customer" />
        <div>
          <div className="customer-slide-decorator decorator--small"/>
          <h5 className="customer-slide-name" >{name}</h5>
          <p className="customer-slide-position" >{position}</p>
          <p className="customer-slide-feedback feedback--desktop" >{feedback}</p>
        </div>
      </div>
      <p className="customer-slide-feedback feedback--mobile" >{feedback}</p>
    </div>
  )

  const renderCustomSlideDots = (i) => (
    <div className="slick-dot-wrapper">
      <p className="slick-dot-title">{customersSliderDots[i].title}</p>
      <div className="slick-dot-decorator" />
    </div>
  )

  return (
    <section  className='home-customers stop-point'>
      <AnimatedWrapper
        renderedChild={({childClass}) => (
          <TitleWithPartlyOverlay
            title={{beforeAnimation: "Our satisfied", animated: "customers", afterAnimation: "say"}}
            className={`home-customers-title ${childClass}`}
          />
        )}
        animationType={"slide"}
        animationDirection={"up"}
        additionalClass={`home-customers-title ${isTitleInView ? "slide-up-in" : "slide-up-out"}`}
        ref={titleRef}
      />

      <div className={`home-customers-slider appear ${isSliderInView ? "appear-in" : "appear-out"}`} ref={sliderRef}>
        <Slider
          dots={true}
          fade={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          customPaging={renderCustomSlideDots}
        >
          {customersList.map(item => renderSlide(item))}
        </Slider>
      </div>
    </section>
  )
}

export default CustomersSection
