import PropTypes from "prop-types";
import './FooterItem.scss'

export const FooterItem = ({title, link, isDisabled}) => {

  return (
    <li className='footer-item'>
      <a href={link} target="_blank" rel="noreferrer" onClick={(e) => {if(isDisabled)e.preventDefault()}} >
        <div className='footer-slider'>
          <p>/&nbsp;{title}</p>
          <p>/&nbsp;{title}</p>
        </div>
      </a>
    </li>
  )
}

FooterItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string
}
