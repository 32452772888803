class VacancyDocParser {
  constructor(setVacancies) {
    this._baseUrl = 'https://tring.peopleforce.io/careers/'
    this.setVacancies = setVacancies
  }

  getDoc = async (url) => {
    const res = await fetch(url)
    const stringifiedHTML = await res.text()
    let parser = new DOMParser();

    return parser.parseFromString(stringifiedHTML, 'text/html');
  }

  getVacancyLinks = async (page= 1) => {
    const linkQuery = page === 1 ? "" : `?page=${page}`
    const doc = await this.getDoc(`${this._baseUrl}${linkQuery}`)

    let links = []
    const anchors = doc.getElementById('results').querySelectorAll('a.stretched-link')

    if(!anchors || !anchors.length) return []

    anchors.forEach(anchor => {
      const anchorLink = anchor.href

      //need check if the link is for vacancy description
      //sometimes get not appropriate link, for example there can be also links for switching pages or stuff like that
      if(anchorLink.includes("tring") && anchorLink.includes("career")) links.push(anchorLink)
    })

    //checks if there are vacancies on 2, 3... pages
    const nextPageLinks = await this.getVacancyLinks(page + 1)
    links.push(...nextPageLinks)
    return links
  }

  getVacancyText = (html)=>{
    let finalTetx='';
    let parser = new DOMParser();
    let res = parser.parseFromString(html, 'text/html');
    let textNodes = res.querySelectorAll('p');
    textNodes= Array.from(textNodes);
    for (let i=0; i<=textNodes.length-1;i++){
      finalTetx = finalTetx + textNodes[i].innerText + ' ';
    }
    return finalTetx;
  }

  //for getting description of vacancy. It is after h2 and consists of different elements
  getAllNextSiblings = (currentElem) => {
    let nextSibling = currentElem.nextElementSibling;

    if(!nextSibling) return ""

    const html = nextSibling.outerHTML + this.getAllNextSiblings(nextSibling)
    return html
  }

  getVacancy = async (url, id) => {
    const doc = await this.getDoc(url)

    const titleEl = doc.querySelector('h2')
    const titleText = titleEl.innerText
    const content = doc.querySelector('.col-lg-8').innerHTML
    const location = doc.querySelector('dl > dd:last-of-type').innerText

    const description = this.getAllNextSiblings(titleEl.nextElementSibling) //cause after h2 usually is hr

    return {
      id,
      title: titleText,
      content,
      location,
      description
    }
  }

  getVacancies = async () => {
    const links = await this.getVacancyLinks()
    const vacancies = await Promise.all(
      links.map((link, index) => this.getVacancy(link, index + 1))
    )
    this.setVacancies(vacancies)
  }
}

export default VacancyDocParser
