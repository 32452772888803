import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    if(document.body.clientWidth<1220){
      window.scrollTo(0,0)
    }else{
      document.getElementById('root').scrollTo(0,0);
    }

  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;
