import FlagUkr from '../../assets/flag-ukraine.svg'
import FlagUSA from '../../assets/flag-usa.svg'
import MapPin from '../../assets/map-pin.svg'

import './Contacts.scss'
import {useIsInViewport} from "../../heplers";
import {TitleWithPartlyOverlay} from "../../components/TitleWithOverlay";
import React from "react";
import {Helmet} from "react-helmet";

export const locationsList = [
  {
    id: `ukr`,
    countryAbbr: `ukr`,
    address: 'Odesa, Ukraine',
    flag: {
      srcImg: FlagUkr,
      alt: 'a flag'
    },
    tel: '+38-094-140-0055',
    email: 'info@etring.com',
    mapLink: 'https://www.google.com/maps/place/%D0%93%D0%B0%D0%B3%D0%B0%D1%80%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%B5+%D0%9F%D0%BB%D0%B0%D1%82%D0%BE,+5%2F1,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%B0,+%D0%9E%D0%B4%D0%B5%D1%81%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D0%B8%D0%BD%D0%B0,+65000/@46.4332162,30.7617616,17z/data=!3m1!4b1!4m5!3m4!1s0x40c6338afad59d87:0xc54b50247e8c8cde!8m2!3d46.4332162!4d30.7639503'
  },
  {
    id: 'usa',
    countryAbbr: 'usa',
    address: 'Mountain View, USA',
    flag: {
      srcImg: FlagUSA,
      alt: 'a flag'
    },
    tel: '+1-650-720-5027',
    email: 'info@etring.com',
    mapLink: 'https://www.google.com/maps/place/1195+Awalt+Dr,+Mountain+View,+CA+94040,+%D0%A1%D0%A8%D0%90/@37.3629844,-122.0713964,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb6c77134aab3:0x6075e76703d7de65!8m2!3d37.3629844!4d-122.0692077'
  }
]

const ContactLocation = ({address, flag, tel, email, countryAbbr, mapLink}) => {
  return (
    <>
      <address>
        <div className='contacts-address' >
          <p>{address}</p>
          <img src={flag.srcImg} alt={flag.alt}/>
        </div>
        <p><a href={`tel:${tel}`}>{tel}</a></p>
        <a href={`mailto:${email}`} className='contacts-email'>{email}</a>
        <div className={`contacts-map contacts-map__${countryAbbr}`} >
          <a
            className='contacts-map__pin'
            href={mapLink}
          >
            <img src={MapPin} alt='map pin' />
          </a>
        </div>
      </address>
    </>
  )
}

const Contacts = () => {
  const [UkrRef, isUkrInView] = useIsInViewport()
  const [USARef, isUSAInView] = useIsInViewport()

  return (
    <div className='contacts page-flex-wrapper'> {/*page-wrapper class is not needed, cause make min-height= 100vh*/}
      <Helmet
        title="Tring | Contacts"
        meta={[
          {"name": "description", "content": "Tring contacts"}
        ]}
      >
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Contacts",
                "item": "https://etring.com/contacts/"
              }]
            }`}
        </script>
      </Helmet>
      <div className='contacts-inner'>
        <TitleWithPartlyOverlay
          title={{beforeAnimation: "our", animated: "locations"}}
          className={"contacts-title"}
        />
        <div className='contacts-locations'>
          <div className='contacts-location' ref={UkrRef}>
            <div className={`appear ${isUkrInView ? 'appear-in' : 'appear-out'}`}>
              <ContactLocation {...locationsList[0]} />
            </div>
          </div>

          <div className='contacts-location' ref={USARef}>
            <div className={`appear ${isUSAInView ? 'appear-in' : 'appear-out'}`}>
              <ContactLocation {...locationsList[1]} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )

}

export default Contacts
