import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import Video from "../../../components/Video";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";

const StartSection = () => {
  const navigateTo = useNavigate()
  const onHireUsBtnClick = () => navigateTo("/hire")
  return (
    <section className="home-start centered-block stop-point">
      <div className="home-start-inner">
        <div className={"home-start-screen"}>
          <div className={"home-start-screen-titles"}>
            <h1 className={"home-subtitle"}>our it company </h1>
            <TitleWithPartlyOverlay
              title={{beforeAnimation: "delivers", animated: "outsourced", afterAnimation: "projects"}}
              className={"home-title"}
            />
          </div>
          <div className={"home-video-wrapper"}>
            <Video />
          </div>
        </div>
        <div className={"home-start-content"}>
          <p className={"home-start-content-text"}>
            Tring is a full-cycle software development outsourcing IT company, specializing in CX solutions and IP telephony.
            We can expand your team by providing dedicated resources. This means no extra effort or overhead.
          </p>
          <div className={"home-start-content-btn-wrapper"}>
            <Button text={"Hire us"} onClick={onHireUsBtnClick}/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StartSection
