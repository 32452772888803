import React from "react";
import {useEffect, useState} from "react";
import {useIsInViewport} from "../../../heplers";
import ModelImg from "../../../assets/homePage/model.svg";
import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import {useIsMobile} from "../../../heplers";


const ModelSection = () => {
  const isDeviceTablet = useIsMobile(1220)

  //for desktop purpose
  const [blockRef, isBlockInViewport] = useIsInViewport()

  const [titleAnimationClass, setTitleAnimationClass] = useState("")
  const [modelAnimationClass, setModelAnimationClass] = useState("")

  const [dot1AnimationClass, setDot1AnimationClass] = useState("")
  const [dot2AnimationClass, setDot2AnimationClass] = useState("")
  const [dot3AnimationClass, setDot3AnimationClass] = useState("")

  const [content1AnimationClass, setContent1AnimationClass] = useState("")
  const [content2AnimationClass, setContent2AnimationClass] = useState("")
  const [content3AnimationClass, setContent3AnimationClass] = useState("")

  //for tablet and mobile purpose
  const [content1Ref, isContent1InViewport] = useIsInViewport()
  const [content2Ref, isContent2InViewport] = useIsInViewport()
  const [content3Ref, isContent3InViewport] = useIsInViewport()

  const decorators = ["person-icon", "services-icon", "team-small"]

  useEffect(() => {
    let timer1 = null;
    let timer2 = null;
    let timer3 = null;
    let timer4 = null;

    if(!isDeviceTablet) {

      //if desktop we show all slide together based on appearence of entire block "blockRef"
      //slides appear with some delay, one by one

      if(isBlockInViewport) {
        timer1 && clearTimeout(timer1)
        timer2 && clearTimeout(timer2)
        timer3 && clearTimeout(timer3)
        timer4 && clearTimeout(timer4)

        setTitleAnimationClass("appear-in")

        timer1 = setTimeout(() => {
          setModelAnimationClass("visability-in");
        }, 20);


        timer2 = setTimeout(() => {
          setContent2AnimationClass("move-ltr-in");
          setDot2AnimationClass("visability-in")
        }, 420);

        timer3 = setTimeout(() => {
          setContent1AnimationClass("move-rtl-in");
          setDot1AnimationClass("visability-in")
        }, 820);

        timer4 = setTimeout(() => {
          setContent3AnimationClass("move-ltr-in");
          setDot3AnimationClass("visability-in")
        }, 1220);

      } else {
        setTitleAnimationClass("appear-out")
        setModelAnimationClass("");

        setContent1AnimationClass("");
        setContent2AnimationClass("");
        setContent3AnimationClass("");

        setDot1AnimationClass("");
        setDot2AnimationClass("");
        setDot3AnimationClass("");
      }
    } else {

      //if it is not desktop, slide need to be animated depends on their appearance in viewport
      if(isBlockInViewport) {
        setTitleAnimationClass("appear-in")

        timer1 = setTimeout(() => {
          setModelAnimationClass("visability-in");
        }, 500);
      } else {
        setTitleAnimationClass("appear-out")
        setModelAnimationClass("");
      }

      isContent1InViewport ? setContent1AnimationClass("move-rtl-in") : setContent1AnimationClass("");
      isContent2InViewport ? setContent2AnimationClass("move-ltr-in") : setContent2AnimationClass("");
      isContent3InViewport ? setContent3AnimationClass("move-rtl-in") : setContent3AnimationClass("");
    }

    return () => {
      timer1 && clearTimeout(timer1)
      timer2 && clearTimeout(timer2)
      timer3 && clearTimeout(timer3)
      timer4 && clearTimeout(timer4)
    };
  }, [isBlockInViewport, isDeviceTablet, isContent1InViewport, isContent2InViewport, isContent3InViewport]);


  const renderModel = (additionalClass) => (
    <div className={`model-wrapper ${additionalClass ? additionalClass : ""}  ${modelAnimationClass}`}>
      {decorators.map((item, order) => (
        <div className="model-decorator-wrapper" key={order}>
          <div className={`${item} svg`}/>
        </div>
      ))}

      <div className="model-overlay model-overlay-1" />
      <div className="model-overlay model-overlay-2" />
      <div className="model-overlay model-overlay-3" />
      <div className="model-overlay model-overlay-4" />

      <img src={ModelImg} alt={"model"} className="model-image"/>
    </div>
  )

  return (
    <section className="home-model stop-point stop-point-center" ref={blockRef}>
      <div className="home-model-header">
        <div className={`animation-wrapper appear ${titleAnimationClass}`}>
          <TitleWithPartlyOverlay
            title={{beforeAnimation: "our model\u00A0of", animated: `${isDeviceTablet ? `out-\nsourcing` : "outsourcing"}`}}
            className={"home-model-title"}
          />
        </div>
        {renderModel("model-image--small")}
      </div>

      <div className={`home-model-content`}>
        <div className="model-col">
          <div className={`model-item move-rtl ${content1AnimationClass}`} ref={content1Ref}>
            <div>
              <div className="model-decorator model-decorator--big services-icon"/>
              <div className="model-decorator model-decorator--small  services-icon svg"/>
            </div>
            <div className="model-item-content">
              <h3 className="model-item-title">Managed Services</h3>
              <p className="model-item-description">
                Outsource part of your IT service. Continuous support of your customers and implementing new changes in customer service
                software require significant input from your IT group. We can lead both processes for you by taking responsibility for the
                software related to your customer service solutions. Let us manage your next development project. We can assemble a team
                with the expertise and experience you require, and manage it from start to end. You set the budget and the objectives, we
                will manage all aspects of the project with full transparency and accountability.
              </p>
            </div>
          </div>
        </div>
        <div className="model-col">
          <div className="model-schema">
            <div>
              <ModelArrow className={dot1AnimationClass} order={"1"}/>
            </div>

            {renderModel()}

            <div>
              <ModelArrow  className={dot2AnimationClass} order={"2"}/>
              <ModelArrow className={dot3AnimationClass} order={"3"}/>
            </div>
          </div>
        </div>
        <div className="model-col">
          <div className={`model-item model-item--upper move-ltr ${content2AnimationClass}`} ref={content2Ref}>
            <div>
              <div className="model-decorator model-decorator--big person-icon"/>
              <div className="model-decorator model-decorator--small  person-icon svg"/>
            </div>
            <div className="model-item-content">
              <h3 className="model-item-title">Extended Team</h3>
              <p className="model-item-description">
                Looking for a pool of technical resources for your software development?
                We can provide dedicated resources to extend your team, without the overhead costs or a lengthy recruiting process.
              </p>
            </div>
          </div>

          <div className={`model-item ${isDeviceTablet ? 'move-rtl' : 'move-ltr'} ${content3AnimationClass}`} ref={content3Ref}>
            <div>
              <div className="model-decorator model-decorator--big people-icon"/>
              <div className="model-decorator model-decorator--small people-icon svg"/>
            </div>
            <div className="model-item-content">
              <h3 className="model-item-title">dedicated Team</h3>
              <p className="model-item-description">
                Improve the performance of your software product and increase your customer loyalty.
                Our experienced team focuses on our client's needs and provides insight to increase the product's value
                by using the best available technologies.
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

const ModelArrow = ({className, order}) => {
  const emptyArrow = []
  emptyArrow.length = 6
  emptyArrow.fill("")

  return (
    <div className={`model-arrow ${className}`} >
      <div className={`model-arrow-overlay model-arrow-overlay-${order}`}/>
      {emptyArrow.map((dot,order) => (<div className={"model-arrow-dot"} key={order}/>))}
    </div>
  )
}

export default ModelSection
