import {useIsInViewport} from "../../../heplers";
import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import AnimatedWrapper from "../../../components/animations/AnimatedWrapper";

const figuresList = [
  {
    id: "1",
    amount: "18",
    description: "years on the market"
  },
  {
    id: "2",
    amount: "60+",
    description: "successful projects"
  },
  {
    id: "3",
    amount: "14",
    description: "years of collaboration with Genesys"
  },
  {
    id: "4",
    amount: "100",
    description: "team members"
  },
  {
    id: "5",
    amount: "50+",
    description: "companies from “Fortune 500” list use software developed by Tring"
  },
]


const Figures = () => {
  const [listRef, isListInViewport] = useIsInViewport()
  const [wrapperRef, isWrapperInViewport] = useIsInViewport()

  return (
    <section className={"home-figures stop-point"}>
      <div  className={"home-figures-content"} ref={wrapperRef}>

        <AnimatedWrapper
          renderedChild={({childClass}) => (
            <TitleWithPartlyOverlay
              title={{beforeAnimation: "About Tring", animated: "in\u00A0figures"}}
              className={`home-figures-title ${childClass}`}
            />
          )}
          animationType={"slide"}
          animationDirection={"up"}
          additionalClass={isWrapperInViewport ? "slide-up-in" : "slide-up-out"}
        />

        <AnimatedWrapper
          renderedChild={({childClass}) => (
            <p className={`appear-smoothing ${childClass}`}>
              Tring provides full cycle outsourced software development services specializing in the areas of customer service solutions and IP telephony.
              With the main office in the US, we are easy to work with and we speak your language.
            </p>
          )}
          animationType={"slide"}
          animationDirection={"up"}
          additionalClass={`${isWrapperInViewport ? "slide-up-in" : "slide-up-out"} home-figures-text`}
        />
      </div>

      <ul className={`home-figures-list flying ${isListInViewport ? "flying-in" : "flying-out"}`} ref={listRef}>
        {figuresList.map(({id, amount, description}) => (
          <li className={"home-figures-item"} key={id}>
            <p className="home-figures-amount">{amount}</p>
            <p className="home-figures-description">{description}</p>
          </li>
        ))
        }
      </ul>
    </section>
  )
}

export default Figures
