import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";

import './NavItem.scss'

export const NavItem = ({title, link, closeMenu}) => {
  return (
    <li className='menu-item'>
      <NavLink
        to={link}
        onClick={closeMenu}
      >
        {title}
      </NavLink>
    </li>
  )
}

NavItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  closeMenu: PropTypes.func
}