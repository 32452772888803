import {useState} from "react";
import PropTypes from "prop-types"
import Button from "../Button";
import {useFormik} from "formik";
import * as yup from "yup";
import {submitHandler} from "./submitHelper";
import SuccessMessage from "./SuccessMessage";

import './Form.scss'

const inputsList = [
  {
    id: "name",
    label: "name",
    type: "text"
  },
  {
    id: "email",
    label: "email",
    type: "email"
  }
]

const getInputList = (additionalInput, basicInputsList = inputsList ) => {
  if(!additionalInput) return basicInputsList

  const additionalField = {
    id: additionalInput,
    label: additionalInput,
    type: "text"
  }

  return [...basicInputsList, additionalField]
}

const basicField = {
  name: '',
  email: '',
}

const basicValidationSchema = {
  name: yup.string().min(2, 'Too Short!').matches(/^([a-zA-Zа-яА-Я]{1,20})[\s]{0,1}([a-zA-Zа-яА-Я0-9_]{0,20})$/, { message:'Not valid name', excludeEmptyString: true })
    .test('Numbers or symbols validation', 'Can’t consist of numbers or other symbols', value => !containsDeviceId(value))
    .required('This field is required'),
  email: yup.string().email('It doesn’t look like an email').required('This field is required'),
}

const containsDeviceId = (string) => /\d/.test(string);

const Form = ({children, CVDataForSubmit, isCVDataNeeded = false, requiredAdditionalField, onFormSubmit}) => {
  const [errMessage, setErrMessage] = useState(null)
  const [isSuccessMessageShown, setSuccessMessageShown] = useState(false)

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    resetForm,
    isValid
  } = useFormik({
    //change initialValues depends on if requiredAdditionalField is provided
    initialValues: requiredAdditionalField
      ? {...basicField, [requiredAdditionalField]: ""}
      : basicField,

    onSubmit: async values => {
      //TODO: return for checking CV receiving
      const additionalData = isCVDataNeeded ? {"cv": CVDataForSubmit} : {}
      const errorText = await submitHandler({...values, ...additionalData})
      // const errorText = null

      if(errorText) {
        setErrMessage(errorText)
      } else {
        onFormSubmit  && onFormSubmit()
        setSuccessMessageShown(true)
        resetForm()
      }
    },

  //change validationSchema depends on if requiredAdditionalField is provided
  validationSchema: requiredAdditionalField
    ? yup.object({...basicValidationSchema, [requiredAdditionalField]: yup.string().required('This field is required')})
    : yup.object(basicValidationSchema)
  })

  return (
    <>
      <form className='form' onSubmit={handleSubmit}>
        {getInputList(requiredAdditionalField).map(({id, label, type}) => (
          <div key={id} className='input-wrapper'>
            <input
              name={label}
              id={id}
              type={type}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[id]}
              className={touched[id] && errors[id] ? 'is-error' : ''}
            />
            <label htmlFor={id}>{label}</label>
            {touched[id] && errors[id]
              ? <p className={'error'}>{errors[id]}</p>
              : null
            }
          </div>
        ))}
        {errMessage ? <p className={'form-error'}>{errMessage}</p> : null}
        {children || <Button text='Send' disabled={!isValid}/>}
        {isSuccessMessageShown && <SuccessMessage onClose={() => setSuccessMessageShown(false)}/>}
      </form>
    </>
  )
}

export default Form

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  CVDataForSubmit: PropTypes.any ,
  isCVDataNeeded: PropTypes.bool,
  requiredAdditionalField: PropTypes.string,
  onSubmit:  PropTypes.func
}



