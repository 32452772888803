import {useIsInViewport} from "../../../heplers";
import AnimatedWrapper from "../../../components/animations/AnimatedWrapper";

const SupportSection = () => {
  const [titleRef, isTitleInView] =  useIsInViewport()
  const [textRef, isTextInView] =  useIsInViewport({rootMargin: "0px 0px 0px 0px"})
  const [logoRef, isLogoInView] =  useIsInViewport()

  return (
    <section className="genesys-support centered-block stop-point">
      <div className="genesys-support-inner">
        <div className='genesys-support-content'>
          <div className={`self-sliding-ltr ${isTitleInView ? "self-sliding-ltr-in" : "self-sliding-ltr-out"}`} ref={titleRef}>
            <div className="genesys-logo img" />
            <h2 className="title genesys-support-title">
              tring supports
              & customizes
              Genesys services
            </h2>
          </div>
          <AnimatedWrapper
            renderedChild={() => (
              <p className="genesys-support-text animated-child" >
                Our partnership with Genesys has been going for about 14 years. Over 200 Tring's employees are supporting, customizing,
                and operating Genesys products for many of the largest and most recognized world leaders in telecommunications, retail, finance, telemedicine,
                and government area. Our customer's success is a cast-iron proof of accelerating ROI and rapid boosting their business.
              </p>
            )}
            animationType={"slide"}
            animationDirection={"up"}
            additionalClass={isTextInView ? "slide-up-in" : ""}
            ref={textRef}
          />
        </div>

        <div className={`genesys-support-logo img self-sliding-rtl ${isLogoInView ? "self-sliding-rtl-in" : "self-sliding-rtl-out"}`} ref={logoRef}/>
      </div>
    </section>
  )
}

export default SupportSection
