import React from "react";
import {Helmet} from "react-helmet";
import StartSection from "./subcomponents/StartSection";
import StrengthsSection from "./subcomponents/StrengthsSection";
import Figures from "./subcomponents/FiguresSection";
import FormSection from "./subcomponents/FormSection";
import ModelSection from "./subcomponents/ModelSection";
import CustomersSection from "./subcomponents/CustomersSection";

//DO NOT delete: it is needed for Slider in CustomerSection
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./home.scss"


const Home = () => {
  return (
    <div className={"home page-wrapper"}>
      <Helmet
        title="Tring | Digital Strategy, Consulting and Engineering"
        meta={[
          {"name": "description", "content": "Tring is a full-cycle software development outsourcing IT company, specializing in CX solutions and IP telephony. We can expand your team by providing dedicated resources. This means no extra effort or overhead."}
        ]}
      >
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Tring",
                "item": "https://etring.com/"
              }]
            }`}
        </script>
      </Helmet>
      <StartSection />
      <StrengthsSection/>
      <ModelSection />
      <Figures />
      <CustomersSection />
      <FormSection />

    </div>
  )
}

export default Home

