import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer"
import Home from "./pages/home2";
import Services from "./pages/services";
import Contacts from "./pages/contacts";
import Genesys from "./pages/genesys";
import Career from "./pages/career";
import JobDescription  from "./pages/career/JobDescription";
import HireUs from "./pages/hireUs";
import {VacanciesProvider} from "./context/VacanciesContext";
import ScrollToTop from "./heplers/scrollToTop";
import Page404 from "./pages/page404";

function App() {

  return (
    <BrowserRouter>
      <Header />
      <main className="container">
        <Helmet
          title="Tring | Services"
          meta={[
            {"name": "description", "content": "We offer a wide range of software development services, including assisting our clients in maintaining existing IT systems and applications, or building new applications"}
          ]}
        />
        <VacanciesProvider>
          <ScrollToTop >
            <Routes>
              <Route index element={<Home />}/>
              <Route path="hire/" element={<HireUs/>}/>
              <Route path="services/" element={<Services/>}/>
              <Route path="genesys/" element={<Genesys/>}/>
              <Route path="contacts/" element={<Contacts/>}/>
              <Route path="career/" element={<Career />}>
                <Route path=":vacancyId" element={<JobDescription />}/>
              </Route>
              <Route path="*" element={<Page404/>}/>
            </Routes>
          </ScrollToTop>
        </VacanciesProvider>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

