import React from "react";

import {useIsInViewport} from "../../../heplers";
import {TitleWithPartlyOverlay} from "../../../components/TitleWithOverlay";
import HeadsetImg from "../../../assets/homePage/headset.svg";
import CodeImg from "../../../assets/homePage/code.svg";
import FrameImg from "../../../assets/homePage/fame.svg";
import PMImg from "../../../assets/homePage/pm.svg";

const strengthsList = [
  {
    id: "1",
    img: {
      src: HeadsetImg,
      alt: "headset"
    },
    title: `CX SERVICE SOLUTIONS &  IP\u00A0TELEPHONY`,
    text: `Endorsed by Genesys, the leader in-cloud and on-premise customer experience solutions, we bring many years of technology 
    and infrastructure management expertise. Looking for a pool of technical resources for your software development? 
    We can provide dedicated resources to extend your team, without the overhead costs or a lengthy recruiting process.`
  },
  {
    id: "2",
    img: {
      src: CodeImg,
      alt: "code"
    },
    title: `FULL cycle of sOFTWARE DEVelopment`,
    text: `We've developed a unique and successful approach. Comprehensive collaboration with clients — our dedicated project 
    teams work closely with client teams on defining and tracking requirements and progress. Application of agile process 
    development, using established project management tools and methodology. Workaround varying location time differences 
    to respond promptly to client requests. Our team is ready to support any stage of a project, including collecting 
    initial requirements, design, definition, development, and continuous improvements.`
  },
  {
    id: "3",
    img: {
      src: FrameImg,
      alt: "frame"
    },
    title: `TEAMS OF SENIOR EXPERTS`,
    text: `Every project needs subject-matter experts who execute it best way. When your project is unique, 
    you need someone who can design and architect, not just execute. Our teams has subject-matter experts and 
    highly experienced industry leaders. Whatever the challenge, we are up for it.`
  },
  {
    id: "4",
    img: {
      src: PMImg,
      alt: "PM"
    },
    title: `EFFECTIVE PROJECT MANAGEMENT`,
    text: `Every successful project needs a plan, and the discipline to follow it. We are good at both :)`
  },
]

const StrengthItem  = ({id, img, title, text}) => {
  const [ref, isInViewport] = useIsInViewport()

  return (
    <li key={id} ref={ref}>
      <div className={`home-strengths-item "}`}>
      <img src={img.src} alt={img.alt}/>
      <h2>{title}</h2>
      <p className="text">{text}</p>
      </div>
    </li>
  )
}

const StrengthsSection = () => {
  const [ref, isInViewport] = useIsInViewport()

  return (
    <section className={"stop-point home-strengths-wrapper"}>
      <div className={`animation-wrapper appear ${isInViewport ? "appear-in" : "appear-out"}`} ref={ref}>
        <TitleWithPartlyOverlay
          title={{beforeAnimation: "our competitive ability &", animated: "strengths"}}
          className={"home-strengths-title"}
        />
      </div>

      <ul className={"home-strengths"}>
        {strengthsList.map((item )=> (
          <StrengthItem {...item} key={item.id} />
        ))
        }
      </ul>
    </section>
  )
}

export default StrengthsSection
